exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bavagli-in-tnt-tsx": () => import("./../../../src/pages/bavagli-in-tnt.tsx" /* webpackChunkName: "component---src-pages-bavagli-in-tnt-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-en-about-us-tsx": () => import("./../../../src/pages/en/about-us.tsx" /* webpackChunkName: "component---src-pages-en-about-us-tsx" */),
  "component---src-pages-en-bibs-tsx": () => import("./../../../src/pages/en/bibs.tsx" /* webpackChunkName: "component---src-pages-en-bibs-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-customize-products-tsx": () => import("./../../../src/pages/en/customize-products.tsx" /* webpackChunkName: "component---src-pages-en-customize-products-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-request-free-sample-tsx": () => import("./../../../src/pages/en/request-free-sample.tsx" /* webpackChunkName: "component---src-pages-en-request-free-sample-tsx" */),
  "component---src-pages-en-wipes-tsx": () => import("./../../../src/pages/en/wipes.tsx" /* webpackChunkName: "component---src-pages-en-wipes-tsx" */),
  "component---src-pages-es-baberos-tsx": () => import("./../../../src/pages/es/baberos.tsx" /* webpackChunkName: "component---src-pages-es-baberos-tsx" */),
  "component---src-pages-es-contactos-tsx": () => import("./../../../src/pages/es/contactos.tsx" /* webpackChunkName: "component---src-pages-es-contactos-tsx" */),
  "component---src-pages-es-index-tsx": () => import("./../../../src/pages/es/index.tsx" /* webpackChunkName: "component---src-pages-es-index-tsx" */),
  "component---src-pages-es-muestra-gratuita-tsx": () => import("./../../../src/pages/es/muestra-gratuita.tsx" /* webpackChunkName: "component---src-pages-es-muestra-gratuita-tsx" */),
  "component---src-pages-es-personalizar-productos-tsx": () => import("./../../../src/pages/es/personalizar-productos.tsx" /* webpackChunkName: "component---src-pages-es-personalizar-productos-tsx" */),
  "component---src-pages-es-sobre-nosotros-tsx": () => import("./../../../src/pages/es/sobre-nosotros.tsx" /* webpackChunkName: "component---src-pages-es-sobre-nosotros-tsx" */),
  "component---src-pages-es-toallitas-desechables-tsx": () => import("./../../../src/pages/es/toallitas-desechables.tsx" /* webpackChunkName: "component---src-pages-es-toallitas-desechables-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-personalizza-prodotti-tsx": () => import("./../../../src/pages/personalizza-prodotti.tsx" /* webpackChunkName: "component---src-pages-personalizza-prodotti-tsx" */),
  "component---src-pages-richiedi-campione-gratuito-tsx": () => import("./../../../src/pages/richiedi-campione-gratuito.tsx" /* webpackChunkName: "component---src-pages-richiedi-campione-gratuito-tsx" */),
  "component---src-pages-salviette-monouso-tsx": () => import("./../../../src/pages/salviette-monouso.tsx" /* webpackChunkName: "component---src-pages-salviette-monouso-tsx" */)
}

